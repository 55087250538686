import axios from "axios";
import { Link } from "gatsby";
import * as React from "react"
import { ArrowIcon } from "../../../components/atoms/Icons";
import Header from "../../../components/Header";

import * as styles from './styles.module.scss'

const Page = ({ params }: any) => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [post, setPost] = React.useState<any>(null)
  const [nextPostUrl, setNextPostUrl] = React.useState<string>("")
  const [categories, setCategories] = React.useState<Array<{ id: string; name: string; }>>([])

  React.useEffect(() => {
    setLoading(true)
    axios.get(`https://blog.fntcyber.com/api/v1/article/${params["post-template"]}`)
      .then(result => {
        const data = result.data
        setPost(data)
        const nextPostArticle = data.next_article_url.split('/').pop()
        setNextPostUrl(`/blog-and-news/${nextPostArticle}`)
        setLoading(false)
      })
      .catch(err => {
        console.log("====================================")
        console.log(`Something bad happened while fetching the data\n${err}`)
        console.log("====================================")
      })

    axios.get("https://blog.fntcyber.com/api/v1/categories")
      .then(result => {
        const data = result.data
        setCategories(data.data)
        setLoading(false)
      })
      .catch(err => {
        console.log("====================================")
        console.log(`Something bad happened while fetching the data\n${err}`)
        console.log("====================================")
      })
  }, [params["post-template"]])

  return (
    <main>
      <Header />
      <div className={styles.container}>
      {loading || !post ? (
        <>
          <div className={styles.loadingSceleton}></div>
        </>
        ) : (
          <>
            <div className={styles.postBanner}>
              <img src={post.title_image} alt="" className={styles.image} />
              <h1 className={styles.title}>{post.title}</h1>
              <div className={styles.postInfo}>
                <div className={styles.category}>{categories.find(({ id }) => id === post.category_id)?.name}</div>
                <div className={styles.readTime}>{post.reading_time} min read</div>
              </div>
            </div>

            <div className={styles.text} dangerouslySetInnerHTML={{__html: post?.content}}></div>

            <Link to={nextPostUrl} className={styles.readNext}>Read next post <ArrowIcon /> </Link>
          </>
        )}
      </div>
    </main>
  );
};
export default Page