// extracted by mini-css-extract-plugin
export var category = "styles-module--category--5pNro";
export var container = "styles-module--container--3OSwT";
export var image = "styles-module--image--IGjSF";
export var loading = "styles-module--loading--t+iid";
export var loadingSceleton = "styles-module--loadingSceleton--1Hxc2";
export var postBanner = "styles-module--postBanner--4cddZ";
export var postImage = "styles-module--postImage--m9X+-";
export var postInfo = "styles-module--postInfo--oOGAV";
export var readNext = "styles-module--readNext--2NQ5R";
export var readTime = "styles-module--readTime--xosyy";
export var text = "styles-module--text--9jUlq";
export var title = "styles-module--title--1inoV";